// import React, { useState, useEffect } from "react";


// import {
//   TextField,
//   Button,
//   Container,
//   Typography,
//   Grid,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
// } from "@mui/material";
// import "../ComponentsCSS/AdminDashboard.css";
// import MaskedInput from "react-text-mask";
// import Header from "../Components/Header";
// import SideBar from "../Components/SideBar";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

// const filter = createFilterOptions();

// function MonthYearInput(props) {
//   const { inputRef, ...other } = props;

//   const [value, setValue] = React.useState(null);
//   // Custom input mask for month and year (MM/YYYY)
//   const mask = [/\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];

//   return (
//     <MaskedInput
//       {...other}
//       inputRef={inputRef} // Use inputRef directly here
//       mask={mask}
//       placeholderChar={"\u2000"}
//       showMask
//     />
//   );
// }

// function AdminDashboard(props) {
//   const [disabled, setDisabled] = useState(false);
//   const [options, setOptions] = useState([]);
//   const [selectedOption, setSelectedOption] = useState("");
//   const [followUpDate, setFollowUpDate] = useState(true);
//   const [contractSigned, setContractSigned] = useState(true);
//   const [products, setProducts] = useState(true);
//   const [products2, setProducts2] = useState(false);

//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     date: "",
//     time: "",
//     sales_team:"",
//     company_name: "",
//     company_address: "",
//     company_phone: "",
//     lead_status: "",
//     follow_up_date: null,
//     feedback: "",
//     products_pitched: "",
//     products_sold: "",
//     price_quoted: "",
//     sales_type: "",
//     sale_amount: "",
//     gst: "",
//     total_amount:"",
//     contract_signed_date: null,
//     client_name: "",
//     client_phone_number: "",
//     client_email: "",
//     adv_bal_amount: "",
//     clearance_amount: "",
//     balance_amount:"",
//     remaining:"",
//     balance_amount1:"",
//     net_amount:"",
//     balance_amount2:"",
//     notes: "",
//   });

//   const [value, setValue] = React.useState(null);

//   const handleDropdownChange = (event) => {
//     const { value } = event.target;
//     if (
//       value === "contract_signed" ||
//       value === "contract_signed_and_contract_paid" ||
//       value === "meet_and_follow_up" ||
//       value === "meet_and_hot_customer"
//     ) {
//       setDisabled(true);
//     } else {
//       setDisabled(false);
//     }

//     if (
//       value === "not_intersted" ||
//       value === "meet_and_not_intersted" ||
//       value === "contract_signed_and_contract_paid"
//     ) {
//       setFollowUpDate(false);
//       console.log(followUpDate);
//     } else {
//       setFollowUpDate(true);
//     }

//     if (
//       value === "contract_signed" ||
//       value === "contract_signed_and_contract_paid"
//     ) {
//       setContractSigned(false);
//       setProducts(false);
//       setProducts2(true);
//     } else {
//       setContractSigned(true);
//       setProducts(true);
//       setProducts2(false);
//     }

//     const { name } = event.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };




//   useEffect(() => {
//     // Calculate total amount whenever sale_amount or GST changes
//     const calculateTotalAmount = () => {
//       const saleAmount = parseFloat(formData.sale_amount);
//       const totalAmount = saleAmount + (saleAmount * formData.gst) / 100;
//       return isNaN(totalAmount) ? "" : totalAmount.toFixed(2);
//     };

//     // Update total_amount in formData whenever sale_amount or GST changes
//     setFormData((prevData) => ({
//       ...prevData,
//       total_amount: calculateTotalAmount(),
//     }));
//   }, [formData.sale_amount,formData.gst]);


//   const handleChange = (e) => {
//     const { name, value } = e.target;

//     if (name === "follow_up_date" || name ==="contract_signed_date") {
//       // Handle date input
//       setFormData((prevState) => ({
//         ...prevState,
//         [name]: value,
//       }));
//     } else {
//       // For other inputs, parse the value as a float if possible
//       let updatedValue = parseFloat(value);
  
//       if (isNaN(updatedValue)) {
//         updatedValue = value;
//       }
  
//       setFormData((prevState) => ({
//         ...prevState,
//         [name]: updatedValue,
//       }));

//     if (name === "clearance_amount" || name === "gst") {
//       const clearanceAmount = parseFloat(formData.clearance_amount);
//       const gstValue = parseFloat(formData.gst);
//       const totalAmount = clearanceAmount + (clearanceAmount * gstValue) / 100;
//       const newBalanceAmount = parseFloat(formData.total_amount) - totalAmount;

//       console.log(parseFloat(formData.total_amount));
//       console.log(totalAmount);

//       setFormData((prevState) => ({
//         ...prevState,
//         balance_amount: newBalanceAmount.toFixed(2),
//       }));
//     }

//     if (name === "remaining" || name === "gst") {
//       const remainingAmount = parseFloat(formData.remaining);
//       const clearanceAmount = parseFloat(formData.clearance_amount);
//       const gst2Value = parseFloat(formData.gst); // Use 'formData.gst2'
//       const totalAmount1 =
//         (clearanceAmount + remainingAmount) * (1 + gst2Value / 100);
//       const newBalanceAmount1 =
//         parseFloat(formData.total_amount) - totalAmount1;

//       setFormData((prevState) => ({
//         ...prevState,
//         balance_amount1: newBalanceAmount1.toFixed(2),
//       }));
//     }


//       if (name === "net_amount") {
//         // Calculate the new balance amount
//         const newBalanceAmount = parseFloat(formData.total_amount) - updatedValue;

//         setFormData((prevState) => ({
//           ...prevState,
//           [name]: updatedValue,
//           balance_amount2: newBalanceAmount.toFixed(2),
//         }));
//       } else {
//         setFormData((prevState) => ({
//           ...prevState,
//           [name]: updatedValue,
//         }));
//       }

//     }

//   };


//   const fetchOptions = () => {
//     axios
//       .get("http://localhost:5002/api/options")
//       .then((response) => {
//         console.log("Response:", response.data); // Log the response data
//         setOptions(response.data);
//       })
//       .catch((error) => {
//         console.error("Error fetching options:", error); // Log any errors
//       });
//   };


//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log("Form submitted:", formData);

//     axios
//       .post("http://localhost:5002/sales", formData)
//       .then((response) => {
//         console.log(response.data);
//         window.alert("successfully submitted");
//         navigate("/DsrTable");
//       })
//       .catch((error) => {
//         console.log(error);
//         window.alert("Error submitting data");
//       });
//   };

//   const formatDate = (date) => {
//     const d = new Date(date);
//     const year = d.getFullYear();
//     const month = String(d.getMonth() + 1).padStart(2, "0");
//     const day = String(d.getDate()).padStart(2, "0");
//     return `${year}-${month}-${day}`;
//   };

//   const formatTime = (time) => {
//     // Assuming time is in format "hh:mm:ss" or "hh:mm"
//     const [hours, minutes] = time.split(":");
//     // Convert hours to 12-hour format and add AM or PM
//     const ampm = parseInt(hours, 10) >= 12 ? "PM" : "AM";
//     const formattedHours = parseInt(hours, 10) % 12 || 12;
//     return `${formattedHours}:${minutes} ${ampm}`;
//   };



//   //to get current date
//   useEffect(() => {
//     // Set the current date when the component mounts
//     const currentDate = new Date().toISOString().split("T")[0]; // Get YYYY-MM-DD format
//     setFormData((prevData) => ({
//       ...prevData,
//       date: currentDate,
//     }));
//   }, []);

//   // to get current date
//   useEffect(() => {
//     // Set the current time when the component mounts
//     const now = new Date();
//     const hours = String(now.getHours()).padStart(2, "0");
//     const minutes = String(now.getMinutes()).padStart(2, "0");
//     const currentTime = `${hours}:${minutes}`;
//     setFormData((prevData) => ({
//       ...prevData,
//       time: currentTime,
//     }));
//   }, []);





  
//   const handleChangee = (newValue) => {
//     let companyName = ""; // Initialize companyName
  
//     // Check if newValue is an event object
//     if (
//       typeof newValue === "object" &&
//       newValue.target &&
//       newValue.target.value
//     ) {
//       // If newValue is an event object, extract company name from its target
//       companyName = newValue.target.value;
//     } else {
//       // If newValue is a primitive value, directly assign it to companyName
//       companyName = newValue !== null ? newValue : "";
//     }
  
//     // Update company_name in the form data
//     setFormData((prevData) => ({
//       ...prevData,
//       company_name: companyName,
//     }));
  
//     // Only send a request to the backend if companyName is not empty
//     if (companyName.trim() !== "") {
//       axios
//         .post("http://localhost:5002/company_details", {
//           company_name: companyName,
//         })
//         .then((response) => {
//           console.log(response.data);
//           // Set the fetched company details in the form data
  
//           // Extract relevant data from the response
//           const { ORG_ADDRESS, ORG_PHONE, ORG_STATUS, FOLLOW_UP_DATE, FEEDBACK } =
//             response.data;
  
//           // Check if FOLLOW_UP_DATE is not null before processing
//           const followUpDate =
//             FOLLOW_UP_DATE !== null ? FOLLOW_UP_DATE.split("T")[0] : null;
  
//           setFormData((prevData) => ({
//             ...prevData,
//             company_address: ORG_ADDRESS,
//             company_phone: ORG_PHONE,
//             lead_status: ORG_STATUS,
//             follow_up_date: followUpDate,
//             feedback: FEEDBACK,
//           }));
  
//           // Set disabled based on the fetched lead status
//           if (
//             ORG_STATUS === "contract_signed" ||
//             ORG_STATUS === "contract_signed_and_contract_paid" ||
//             ORG_STATUS === "meet_and_follow_up" ||
//             ORG_STATUS === "meet_and_hot_customer"
//           ) {
//             setDisabled(true);
//           } else {
//             setDisabled(false);
//           }
//         })
//         .catch((error) => {
//           console.error("Error fetching company data:", error);
//           // Handle errors if needed
//         });
//     }
//   };

//   const handleSelectChange = (event) => {
//     const { value } = event.target;
//     setSelectedOption(value); // Update the selected option
//     // Optionally, you can also update the form data if needed
//     setFormData((prevState) => ({
//       ...prevState,
//       adv_bal_amount: value,
//     }));
//   };
  

//   const title = "DSR";

//   return (
//     <div className="background">
//       <Header title={title} />

//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <SideBar />

//         <Container style={{ padding: 0 }}>
//           <div id="main">
//             {/* <Typography variant="h4" align="center" gutterBottom>
//               DSR
//             </Typography> */}

//             <form onSubmit={handleSubmit} id="form">
//               <br></br>
//               <Grid container spacing={3} justifyContent="flex-end">
//                 <Grid item xs={11} sm={5} md={4} lg={2}>
//                   <TextField
//                     fullWidth
//                     label="Date"
//                     type="date"
//                     name="date"
//                     value={formData.date}
//                     onChange={handleChange}
//                     variant="outlined"
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                   />
//                 </Grid>

//                 <Grid item xs={11} sm={5} md={4} lg={2}>
//                   <TextField
//                     fullWidth
//                     label="Time"
//                     type="time"
//                     name="time"
//                     value={formData.time}
//                     onChange={handleChange}
//                     variant="outlined"
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                   />
//                 </Grid>

//                 <Grid item xs={11} sm={5} md={4} lg={3}>
//                   <FormControl fullWidth variant="outlined">
//                     <InputLabel>Sales Team</InputLabel>
//                     <Select
//                       label="Sales Team"
//                       name="sales_team"
//                       value={formData.sales_team}
//                       onChange={handleDropdownChange}
//                     >
//                       <MenuItem value="bala">Bala</MenuItem>
//                       <MenuItem value="babu_kumar">Babu Kumar</MenuItem>
//                       <MenuItem value="santhi_kumar">Santhi Kumar</MenuItem>
//                       <MenuItem value="keerthi">Keerthi</MenuItem>
//                     </Select>
//                   </FormControl>
//                 </Grid>
//               </Grid>

//               <Grid container spacing={3} className="grid2">
//                 <Grid item xs={12}>
//                   <Typography
//                     variant="h6"
//                     align="left"
//                     style={{
//                       background:
//                         "linear-gradient(90deg, rgba(18,18,162,1) 5%, rgba(31,25,144,1) 37%, rgba(255,255,255,1) 100%)",
//                       fontSize: "15px",
//                       fontWeight: "bold",
//                       color: "white",
//                     }}
//                   >
//                     Company Details
//                   </Typography>
//                 </Grid>

                
//                 <Grid item xs={11} sm={5} md={4} lg={3}>
//   <Autocomplete
//     value={formData.company_name} // Use formData.company_name as the value
//     onChange={(event, newValue) => {
//       setValue(newValue);
//       handleChangee(event, newValue);
//     }}
//     options={options.map((option) => option.ORG_NAME)}
//     renderInput={(params) => (
//       <TextField
//         {...params}
//         label="Company Name"
//         name="company_name"
//         onChange={handleChangee}
//         required
//       />
//     )}
//     freeSolo
//   />
// </Grid>

//                 <Grid item xs={11} sm={5} md={4} lg={3}>
//                   <TextField
//                     fullWidth
//                     label="Address"
//                     name="company_address"
//                     value={formData.company_address}
//                     onChange={handleChange}
//                     variant="outlined"
//                     required
//                   />
//                 </Grid>

//                 <Grid item xs={11} sm={5} md={4} lg={3}>
//                   <TextField
//                     fullWidth
//                     label="Phone"
//                     name="company_phone"
//                     value={formData.company_phone}
//                     onChange={handleChange}
//                     variant="outlined"
//                     required
//                   />
//                 </Grid>

//                 <Grid item xs={11} sm={5} md={4} lg={3}>
//                   <FormControl fullWidth variant="outlined">
//                     <InputLabel>Lead Status</InputLabel>
//                     <Select
//                       label="Lead Status"
//                       name="lead_status"
//                       value={formData.lead_status}
//                       onChange={handleDropdownChange}
//                       required
//                     >
//                       <MenuItem value="not_intersted">Not Intersted</MenuItem>
//                       <MenuItem value="no_response">No Response </MenuItem>
//                       <MenuItem value="appointment_schedule">
//                         Appointment Scheduled{" "}
//                       </MenuItem>
//                       <MenuItem value="meet_and_not_intersted">
//                         Met and Not Intersted{" "}
//                       </MenuItem>
//                       <MenuItem value="meet_and_hot_customer">
//                         Met and Hot Customer{" "}
//                       </MenuItem>
//                       <MenuItem value="meet_and_follow_up">
//                         Met and Follow Up{" "}
//                       </MenuItem>
//                       <MenuItem value="contract_signed_and_contract_paid">
//                         Contract Signed and Contract Paid{" "}
//                       </MenuItem>
//                       <MenuItem value="contract_signed">
//                         Contract Signed{" "}
//                       </MenuItem>
//                     </Select>
//                   </FormControl>
//                 </Grid>
//                 {contractSigned && (
//                   <Grid item xs={11} sm={5} md={4} lg={9}>
//                     <TextField
//                       fullWidth
//                       label="FeedBack"
//                       type="text"
//                       name="feedback"
//                       value={formData.feedback}
//                       onChange={handleChange}
//                       variant="outlined"
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                       required
//                     />
//                   </Grid>
//                 )}
//                 {followUpDate && (
//                   <Grid item xs={11} sm={5} md={4} lg={3}>
//                     <TextField
//                       fullWidth
//                       label="Follow Up Date"
//                       type="date"
//                       name="follow_up_date"
//                       value={formData.follow_up_date}
//                       onChange={handleChange}
//                       variant="outlined"
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                       disabled={!disabled}
//                     />
//                   </Grid>
//                 )}

//                 {!(
//                   formData.lead_status === "not_intersted" ||
//                   formData.lead_status === "no_response" ||
//                   formData.lead_status === "appointment_schedule" ||
//                   formData.lead_status === "meet_and_not_intersted"
//                 ) && (
//                   <>
//                     <Grid item xs={12}>
//                       <Typography
//                         variant="h6"
//                         align="left"
//                         style={{
//                           background:
//                             "linear-gradient(90deg, rgba(18,18,162,1) 5%, rgba(31,25,144,1) 37%, rgba(255,255,255,1) 100%)",
//                           fontSize: "15px",
//                           fontWeight: "bold",
//                           color: "white",
//                         }}
//                       >
//                         Product Details
//                       </Typography>
//                     </Grid>
//                     {products2 && (
//                       <Grid item xs={11} sm={5} md={4} lg={3}>
//                           <TextField
//                             fullWidth
//                             label="Products Sold"
//                             type="text"
//                             name="products_sold"
//                             value={formData.products_sold}
//                             onChange={handleChange}
//                             variant="outlined"
//                             disabled={!disabled}
//                           />
//                         </Grid>
//                     )}
//                     {products && (
//                       <>
//                         <Grid item xs={11} sm={5} md={4} lg={3}>
//                           <TextField
//                             fullWidth
//                             label="Products Pitched"
//                             type="text"
//                             name="products_pitched"
//                             value={formData.products_pitched}
//                             onChange={handleChange}
//                             variant="outlined"
//                             disabled={!disabled}
//                           />
//                         </Grid>
//                         <Grid item xs={11} sm={5} md={4} lg={3}>
//                           <TextField
//                             fullWidth
//                             label="Products Sold"
//                             type="number"
//                             name="products_sold"
//                             value={formData.products_sold}
//                             onChange={handleChange}
//                             variant="outlined"
//                             disabled={!disabled}
//                           />
//                         </Grid>
//                         <Grid item xs={11} sm={5} md={4} lg={3}>
//                           <TextField
//                             fullWidth
//                             label="Price Quoted"
//                             type="number"
//                             name="price_quoted"
//                             value={formData.price_quoted}
//                             onChange={handleChange}
//                             variant="outlined"
//                             disabled={!disabled}
//                           />
//                         </Grid>
//                       </>
//                     )}

//                     <Grid item xs={12}>
//                       <Typography
//                         variant="h6"
//                         align="left"
//                         style={{
//                           background:
//                             "linear-gradient(90deg, rgba(18,18,162,1) 5%, rgba(31,25,144,1) 37%, rgba(255,255,255,1) 100%)",
//                           fontSize: "15px",
//                           fontWeight: "bold",
//                           color: "white",
//                         }}
//                       >
//                         Sale Details
//                       </Typography>
//                     </Grid>
//                     <Grid item xs={11} sm={5} md={4} lg={3}>
//                       <FormControl fullWidth variant="outlined">
//                         <InputLabel>Sales Type</InputLabel>
//                         <Select
//                           label="Sales Type"
//                           name="sales_type"
//                           value={formData.sales_type}
//                           onChange={handleChange}
//                           disabled={!disabled}
//                         >
//                           <MenuItem value="new">New </MenuItem>
//                           <MenuItem value="renewal"> Renewal </MenuItem>
//                           <MenuItem value="balance"> Balance </MenuItem>
//                         </Select>
//                       </FormControl>
//                     </Grid>


//                     <Grid item xs={11} sm={5} md={4} lg={3}>
//                       <TextField
//                         fullWidth
//                         label="Sales Amount"
//                         type="number"
//                         name="sale_amount"
//                         value={formData.sale_amount}
//                         onChange={handleChange}
//                         variant="outlined"
//                         disabled={!disabled}
//                       />
//                     </Grid>

//                     <Grid item xs={11} sm={5} md={4} lg={3}>
//                       <TextField
//                         select
//                         fullWidth
//                         label="GST"
//                         name="gst"
//                         value={formData.gst}
//                         variant="outlined"
//                         onChange={handleChange}
//                       >
//                         <MenuItem disabled>
//                           <b>GST</b>
//                         </MenuItem>
//                         <MenuItem value={18}>18%</MenuItem>
//                         <MenuItem value={0}>0%</MenuItem>
//                       </TextField>
//                     </Grid>

//                     <Grid item xs={11} sm={5} md={4} lg={3}>
//                       <TextField
//                         fullWidth
//                         label="Total Amount"
//                         type="number"
//                         name="total_amount"
//                         value={formData.total_amount}
//                         onChange={handleChange} // No need to change the value of Total Amount
//                         variant="outlined"
//                         disabled={!disabled}
//                         InputLabelProps={{
//                           shrink: true,
//                         }}
//                       />
//                     </Grid>

//                     <Grid item xs={11} sm={5} md={4} lg={3}>
//                       <TextField
//                         fullWidth
//                         label="Contract Signed Date"
//                         type="date"
//                         name="contract_signed_date"
//                         value={formData.contract_signed_date}
//                         onChange={handleChange}
//                         variant="outlined"
//                         InputLabelProps={{
//                           shrink: true,
//                         }}
//                         disabled={!disabled}
//                       />
//                     </Grid>

//                     <Grid item xs={11} sm={5} md={4} lg={3}>
//                       <TextField
//                         fullWidth
//                         label="Client Name"
//                         name="client_name"
//                         value={formData.client_name}
//                         onChange={handleChange}
//                         variant="outlined"
//                         disabled={!disabled}
//                       />
//                     </Grid>

//                     <Grid item xs={11} sm={5} md={4} lg={3}>
//                       <TextField
//                         fullWidth
//                         label="Client Phone Number"
//                         type="number"
//                         name="client_phone_number"
//                         value={formData.client_phone_number}
//                         onChange={handleChange}
//                         variant="outlined"
//                         disabled={!disabled}
//                       />
//                     </Grid>

//                     <Grid item xs={11} sm={5} md={4} lg={3}>
//                       <TextField
//                         fullWidth
//                         label="Client Email"
//                         type="email"
//                         name="client_email"
//                         value={formData.client_email}
//                         onChange={handleChange}
//                         variant="outlined"
//                         disabled={!disabled}
//                       />
//                     </Grid>
//                     <Grid item xs={12}>
//                       <Typography
//                         variant="h6"
//                         align="left"
//                         style={{
//                           background:
//                             "linear-gradient(90deg, rgba(18,18,162,1) 5%, rgba(31,25,144,1) 37%, rgba(255,255,255,1) 100%)",
//                           fontSize: "15px",
//                           fontWeight: "bold",
//                           color: "white",
//                         }}
//                       >
//                         Payment Details
//                       </Typography>
//                     </Grid>
//                     <Grid item xs={11} sm={5} md={4} lg={3}>
//                       <FormControl fullWidth variant="outlined">
//                         <InputLabel>Adv/Balance Amount</InputLabel>
//                         <Select
//                           label="Adv/Balance Amount"
//                           name="adv_bal_amount"
//                           onChange={handleSelectChange}
//                           value={formData.adv_bal_amount}
//                           style={{ marginTop: "8px" }}
//                           id="outlined-size-small"
//                           size="small"
//                           disabled={!disabled}
//                         >
//                           <MenuItem value="advance">Advance Amount</MenuItem>
//                           <MenuItem value="balance">Balance Amount</MenuItem>
//                           <MenuItem value="total">Total Amount</MenuItem>
//                         </Select>
//                       </FormControl>
//                     </Grid>

//                     {selectedOption === "advance" && (
//                       <React.Fragment>
//                         <Grid item xs={11} sm={5} md={4} lg={3}>
//                           <TextField
//                             fullWidth
//                             label="Clearance Amount"
//                             type="number"
//                             name="clearance_amount"
//                             value={formData.clearance_amount}
//                             variant="outlined"
//                             onChange={handleChange}
//                           />
//                         </Grid>

//                         <Grid item xs={11} sm={5} md={4} lg={3}>
//                           <TextField
//                             fullWidth
//                             label="Balance Amount"
//                             type="number"
//                             name="balance_amount"
//                             value={
//                               formData.balance_amount === "0" ||
//                               formData.balance_amount === 0
//                                 ? "0"
//                                 : formData.balance_amount
//                             }
//                             variant="outlined"
//                           />
//                         </Grid>
//                       </React.Fragment>
//                     )}

//                     {selectedOption === "balance" && (
//                       <React.Fragment>
//                         <Grid item xs={11} sm={5} md={4} lg={3}>
//                           <TextField
//                             fullWidth
//                             label="remaining"
//                             type="number"
//                             name="remaining"
//                             placeholder={formData.clearance_amount}
//                             value={formData.remaining}
//                             variant="outlined"
//                             onChange={handleChange}
//                           />
//                         </Grid>

                   

//                         <Grid item xs={11} sm={5} md={4} lg={3}>
//                           <TextField
//                             fullWidth
//                             label="Balance Amount1"
//                             type="number"
//                             name="balance_amount1"
//                             value={formData.balance_amount1}
//                             variant="outlined"
//                           />
//                         </Grid>
//                       </React.Fragment>
//                     )}

//                     {selectedOption === "total" && (
//                       <React.Fragment>
//                         <Grid item xs={11} sm={5} md={4} lg={3}>
//                           <TextField
//                             fullWidth
//                             label="net_amount"
//                             type="number"
//                             name="net_amount"
//                             value={formData.net_amount}
//                             variant="outlined"
//                             onChange={handleChange}
//                             InputLabelProps={{
//                               shrink: true,
//                             }}
//                           />
//                         </Grid>

//                         <Grid item xs={11} sm={5} md={4} lg={3}>
//                           <TextField
//                             fullWidth
//                             label="Balance Amount2"
//                             type="number"
//                             name="balance_amount2"
//                             value={formData.balance_amount2}
//                             variant="outlined"
//                             InputLabelProps={{
//                               shrink: true,
//                             }}
//                           />
//                         </Grid>
//                       </React.Fragment>
//                     )}

//                     <Grid item xs={11} sm={5} md={4} lg={3}>
//                       <FormControl fullWidth variant="outlined">
//                         <InputLabel>Payment Mode</InputLabel>
//                         <Select
//                           label="Payment Mode"
//                           name="payment_mode"
//                           value={formData.payment_mode}
//                           onChange={handleChange}
//                           style={{ marginTop: "8px" }}
//                           id="outlined-size-small"
//                           size="small"
//                         >
//                           <MenuItem value="new">Cash </MenuItem>
//                           <MenuItem value="renewal"> Check </MenuItem>
//                           <MenuItem value="balance"> Net Banking </MenuItem>
//                         </Select>
//                       </FormControl>
//                     </Grid>
//                   </>
//                 )}

//                 <Grid item xs={12}>
//                   <Typography
//                     variant="h6"
//                     align="left"
//                     style={{
//                       background:
//                         "linear-gradient(90deg, rgba(18,18,162,1) 5%, rgba(31,25,144,1) 37%, rgba(255,255,255,1) 100%)",
//                       fontSize: "15px",
//                       fontWeight: "bold",
//                       color: "white",
//                     }}
//                   >
//                     Comments
//                   </Typography>
//                 </Grid>

//                 <Grid item xs={11} sm={5} md={4} lg={12}>
//                   <TextField
//                     fullWidth
//                     label="Notes"
//                     rows={3}
//                     name="notes"
//                     value={formData.notes}
//                     onChange={handleChange}
//                     variant="outlined"
//                   />
//                 </Grid>

//                 <br />

//                 <Grid item xs={12} sm={4}>
//                   <Button
//                     type="submit"
//                     variant="contained"
//                     color="primary"
//                     fullWidth
//                   >
//                     Submit
//                   </Button>
//                 </Grid>
//               </Grid>
//             </form>
//           </div>
//         </Container>
//       </div>
//     </div>
//   );
// }

// export default AdminDashboard;


import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import "../ComponentsCSS/AdminDashboard.css";
import MaskedInput from "react-text-mask";
import Header from "../Components/Header";
import SideBar from "../Components/SideBar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

const filter = createFilterOptions();

function MonthYearInput(props) {
  const { inputRef, ...other } = props;

  const [value, setValue] = React.useState(null);
  // Custom input mask for month and year (MM/YYYY)
  const mask = [/\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];

  return (
    <MaskedInput
      {...other}
      inputRef={inputRef} // Use inputRef directly here
      mask={mask}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

function Dsr(props) {
  const [disabled, setDisabled] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [followUpDate, setFollowUpDate] = useState(true);
  const [contractSigned, setContractSigned] = useState(true);
  const [products, setProducts] = useState(true);
  const [products2, setProducts2] = useState(false);



  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    date: "",
    time: "",
    sales_team: "",
    company_name: "",
    company_address: "",
    company_phone: "",
    lead_status: "",
    follow_up_date: null,
    feedback: "",

    products_pitched: "",
    products_sold: "",
    price_quoted: "",
    
    sales_type: "",
    sale_amount: "",
    gst: "",
    total_amount: "",
    contract_signed_date: null,
    client_name: "",
    client_phone_number: "",
    client_email: "",
    adv_bal_amount: "",
    clearance_amount: "",
    balance_amount: "",
    remaining: "",
    balance_amount1: "",
    net_amount: "",
    balance_amount2: "",
    payment_mode:"",
    notes: "",
  });

  const [value, setValue] = React.useState(null);

  const handleDropdownChange = (event) => {
    const { value } = event.target;
    if (
      value === "contract_signed" ||
      value === "contract_signed_and_contract_paid" ||
      value === "meet_and_follow_up" ||
      value === "meet_and_hot_customer"
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }

    if (
      value === "not_intersted" ||
      value === "meet_and_not_intersted" ||
      value === "contract_signed_and_contract_paid"
    ) {
      setFollowUpDate(false);
      console.log(followUpDate);
    } else {
      setFollowUpDate(true);
    }

    if (
      value === "contract_signed" ||
      value === "contract_signed_and_contract_paid"
    ) {
      setContractSigned(false);
      setProducts(false);
      setProducts2(true);
    } else {
      setContractSigned(true);
      setProducts(true);
      setProducts2(false);
    }

    const { name } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };





  const [gstOptions] = useState([
    { value: 18, label: "18%" },
    { value: 0, label: "0%" },
  ]);

  const [gst, setGst] = useState(18); // State variable to hold GST percentage

  useEffect(() => {
    // Calculate total amount whenever sale_amount or GST changes
    const calculateTotalAmount = () => {
      const saleAmount = parseFloat(formData.sale_amount);
      const gstValue = parseFloat(formData.gst);
      const totalAmount = saleAmount + (saleAmount * gstValue) / 100;
      return isNaN(totalAmount) ? "" : totalAmount.toFixed(2);
    };

    // Update total_amount in formData whenever sale_amount or GST changes
    setFormData((prevData) => ({
      ...prevData,
      total_amount: calculateTotalAmount(),
    }));
  }, [formData.sale_amount, formData.gst]);

  useEffect(() => {
    if (formData.clearance_amount !== "" && formData.gst !== "") {
      const clearanceAmount = parseFloat(formData.clearance_amount);
      const gstValue = parseFloat(formData.gst);
      const totalAmount = clearanceAmount + (clearanceAmount * gstValue) / 100;
      const newBalanceAmount = parseFloat(formData.total_amount) - totalAmount;

      setFormData((prevState) => ({
        ...prevState,
        balance_amount: newBalanceAmount.toFixed(2),
      }));
    }
  }, [formData.clearance_amount, formData.gst, formData.total_amount]);

  useEffect(() => {
    if (formData.remaining !== "" && formData.gst !== "") {
      const remainingAmount = parseFloat(formData.remaining);

      console.log(formData.remaining);
      const clearanceAmount = parseFloat(formData.clearance_amount);
      const gst2Value = parseFloat(formData.gst);
      const totalAmount1 =
        (clearanceAmount + remainingAmount) * (1 + gst2Value / 100);
      const newBalanceAmount1 =
        parseFloat(formData.total_amount) - totalAmount1;

      setFormData((prevState) => ({
        ...prevState,
        balance_amount1: newBalanceAmount1.toFixed(2),
      }));
    }
  }, [formData.remaining, formData.gst, formData.total_amount]);


  useEffect(() => {
    fetchOptions();
  }, []); // Fetch options on component mount

  const fetchOptions = () => {
    axios
      .get("http://localhost:5002/api/options")
      .then((response) => {
        console.log("Response:", response.data); // Log the response data
        setOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching options:", error); // Log any errors
      });
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);

    axios
      .post("http://localhost:5002/sales", formData)
      .then((response) => {
        console.log(response.data);
        window.alert("successfully submitted");
        navigate("/DsrTable");
      })
      .catch((error) => {
        console.log(error);
        window.alert("Error submitting data");
      });
  };



    const handleChange = (e) => {
      const { name, value } = e.target;
      if (name === "follow_up_date" || name === "contract_signed_date") {
        // Handle date input
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else {
        // For other inputs, parse the value as a float if possible
        let updatedValue = parseFloat(value);

        if (isNaN(updatedValue)) {
          updatedValue = value;
        }

        setFormData((prevState) => ({
          ...prevState,
          [name]: updatedValue,
        }));

        if (name === "clearance_amount" || name === "gst") {
          const clearanceAmount = parseFloat(formData.clearance_amount);
          const gstValue = parseFloat(formData.gst);
          const totalAmount =
            clearanceAmount + (clearanceAmount * gstValue) / 100;
          const newBalanceAmount =
            parseFloat(formData.total_amount) - totalAmount;

          console.log(parseFloat(formData.total_amount));
          console.log(totalAmount);

          setFormData((prevState) => ({
            ...prevState,
            balance_amount: newBalanceAmount.toFixed(2),
          }));
        }

        if (name === "remaining" || name === "gst") {
          const remainingAmount = parseFloat(formData.remaining);
          const clearanceAmount = parseFloat(formData.clearance_amount);
          const gst2Value = parseFloat(formData.gst); // Use 'formData.gst2'
          const totalAmount1 =
            (clearanceAmount + remainingAmount) * (1 + gst2Value / 100);
          const newBalanceAmount1 =
            parseFloat(formData.total_amount) - totalAmount1;

          setFormData((prevState) => ({
            ...prevState,
            balance_amount1: newBalanceAmount1.toFixed(2),
          }));
        }

        if (name === "net_amount") {
          // Calculate the new balance amount
          const newBalanceAmount =
            parseFloat(formData.total_amount) - updatedValue;

          setFormData((prevState) => ({
            ...prevState,
            [name]: updatedValue,
            balance_amount2: newBalanceAmount.toFixed(2),
          }));
        } else {
          setFormData((prevState) => ({
            ...prevState,
            [name]: updatedValue,
          }));
        }
      }
    };



    const handleChangee = (newValue) => {
      let companyName = ""; // Initialize companyName

      // Check if newValue is an event object
      if (
        typeof newValue === "object" &&
        newValue.target &&
        newValue.target.value
      ) {
        // If newValue is an event object, extract company name from its target
        companyName = newValue.target.value;
      } else {
        // If newValue is a primitive value, directly assign it to companyName
        companyName = newValue !== null ? newValue : "";
      }

      // Update company_name in the form data
      setFormData((prevData) => ({
        ...prevData,
        company_name: companyName,
      }));

      // Only send a request to the backend if companyName is not empty
      if (companyName.trim() !== "") {
        axios
          .post("http://localhost:5002/company_details", {
            company_name: companyName,
          })
          .then((response) => {
            console.log(response.data);
            // Set the fetched company details in the form data

            // Extract relevant data from the response
            const { ORG_ADDRESS, ORG_PHONE, ORG_STATUS, FOLLOW_UP_DATE, FEEDBACK } =
              response.data;

            // Check if FOLLOW_UP_DATE is not null before processing
            const followUpDate =
              FOLLOW_UP_DATE !== null ? FOLLOW_UP_DATE.split("T")[0] : null;

            setFormData((prevData) => ({
              ...prevData,
              company_address: ORG_ADDRESS,
              company_phone: ORG_PHONE,
              lead_status: ORG_STATUS,
              follow_up_date: followUpDate,
              feedback: FEEDBACK,
            }));

            // Set disabled based on the fetched lead status
            if (
              ORG_STATUS === "contract_signed" ||
              ORG_STATUS === "contract_signed_and_contract_paid" ||
              ORG_STATUS === "meet_and_follow_up" ||
              ORG_STATUS === "meet_and_hot_customer"
            ) {
              setDisabled(true);
            } else {
              setDisabled(false);
            }
          })
          .catch((error) => {
            console.error("Error fetching company data:", error);
            // Handle errors if needed
          });
      }
    };



    const handleSelectChange = (event) => {
      const { value } = event.target;
      setSelectedOption(value); // Update the selected option
      // Optionally, you can also update the form data if needed
      setFormData((prevState) => ({
        ...prevState,
        adv_bal_amount: value,
      }));
    };



    //to get current date
    useEffect(() => {
      // Set the current date when the component mounts
      const currentDate = new Date().toISOString().split("T")[0]; // Get YYYY-MM-DD format
      setFormData((prevData) => ({
        ...prevData,
        date: currentDate,
      }));
    }, []);
  
    useEffect(() => {
      // Set the current time when the component mounts
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const currentTime = `${hours}:${minutes}`;
      setFormData((prevData) => ({
        ...prevData,
        time: currentTime,
      }));
    }, []);

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const formatTime = (time) => {
    // Assuming time is in format "hh:mm:ss" or "hh:mm"
    const [hours, minutes] = time.split(":");
    // Convert hours to 12-hour format and add AM or PM
    const ampm = parseInt(hours, 10) >= 12 ? "PM" : "AM";
    const formattedHours = parseInt(hours, 10) % 12 || 12;
    return `${formattedHours}:${minutes} ${ampm}`;
  };

  const title = "DSR";

  return (
    <div className="background">
      <Header title={title} />

      <div style={{ display: "flex", flexDirection: "row" }}>
        <SideBar />

        <Container style={{ padding: 0 }}>
          <div id="main">
            {/* <Typography variant="h4" align="center" gutterBottom>
              DSR
            </Typography> */}

            <form onSubmit={handleSubmit} id="form">
              <br></br>
              <Grid container spacing={3} justifyContent="flex-end">
                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Date"
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Time"
                    type="time"
                    name="time"
                    value={formData.time}
                    onChange={handleChange}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Sales Team</InputLabel>
                    <Select
                      label="sales_team"
                      name="sales_team"
                      value={formData.sales_team}
                      onChange={handleDropdownChange}
                    >
                      <MenuItem value="Bala">Bala</MenuItem>
                      <MenuItem value="Babu Kumar">Babu Kumar</MenuItem>
                      <MenuItem value="Santhi Kumar">Santhi Kumar</MenuItem>
                      <MenuItem value="Keerthi">Keerthi</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={3} className="grid2">
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    align="left"
                    style={{
                      background:
                        "linear-gradient(90deg, rgba(18,18,162,1) 5%, rgba(31,25,144,1) 37%, rgba(255,255,255,1) 100%)",
                      fontSize: "15px",
                      fontWeight: "bold",
                      color: "white",
                      paddingLeft: "20px",
                    }}
                  >
                    Company Details
                  </Typography>
                </Grid>
                <Grid item xs={11} sm={5} md={4} lg={3}>
                  {/* <TextField
                    fullWidth
                    label="Company Name"
                    name="company_name"
                    value={formData.company_name}
                    onChange={handleChange}
                    variant="outlined"
                  /> */}

                  <Autocomplete
                    value={value}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                      handleChangee(newValue);
                    }}
                    options={options.map((option) => option.ORG_NAME)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        type="text"
                        label="Company Name"
                        name="company_name"
                        value={formData.company_name}
                        onChange={handleChangee}
                        required
                      />
                    )}
                    freeSolo
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Address"
                    name="company_address"
                    value={formData.company_address}
                    onChange={handleChange}
                    variant="outlined"
                    required
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Phone"
                    name="company_phone"
                    value={formData.company_phone}
                    onChange={handleChange}
                    variant="outlined"
                    required
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Lead Status</InputLabel>
                    <Select
                      label="Lead Status"
                      name="lead_status"
                      value={formData.lead_status}
                      onChange={handleDropdownChange}
                      required
                    >
                      <MenuItem value="not_intersted">Not Intersted</MenuItem>
                      <MenuItem value="no_response">No Response </MenuItem>
                      <MenuItem value="appointment_schedule">
                        Appointment Scheduled{" "}
                      </MenuItem>
                      <MenuItem value="meet_and_not_intersted">
                        Met and Not Intersted{" "}
                      </MenuItem>
                      <MenuItem value="meet_and_hot_customer">
                        Met and Hot Customer{" "}
                      </MenuItem>
                      <MenuItem value="meet_and_follow_up">
                        Met and Follow Up{" "}
                      </MenuItem>
                      <MenuItem value="contract_signed_and_contract_paid">
                        Contract Signed and Contract Paid{" "}
                      </MenuItem>
                      <MenuItem value="contract_signed">
                        Contract Signed{" "}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {contractSigned && (
                  <Grid item xs={11} sm={5} md={4} lg={9}>
                    <TextField
                      fullWidth
                      label="FeedBack"
                      name="feedback"
                      value={formData.feedback}
                      onChange={handleChange}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                    />
                  </Grid>
                )}
                {followUpDate && (
                  <Grid item xs={11} sm={5} md={4} lg={3}>
                    <TextField
                      fullWidth
                      label="Follow Up Date"
                      type="date"
                      name="follow_up_date"
                      value={formData.follow_up_date}
                      onChange={handleChange}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={!disabled}
                    />
                  </Grid>
                )}

                {!(
                  formData.lead_status === "not_intersted" ||
                  formData.lead_status === "no_response" ||
                  formData.lead_status === "appointment_schedule" ||
                  formData.lead_status === "meet_and_not_intersted"
                ) && (
                  <>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        align="left"
                        style={{
                          background:
                            "linear-gradient(90deg, rgba(18,18,162,1) 5%, rgba(31,25,144,1) 37%, rgba(255,255,255,1) 100%)",
                          fontSize: "15px",
                          fontWeight: "bold",
                          color: "white",
                          paddingLeft: "20px",
                        }}
                      >
                        Product Details
                      </Typography>
                    </Grid>
                    {products2 && (
                      <Grid item xs={11} sm={5} md={4} lg={3}>
                        <TextField
                          fullWidth
                          label="Products Sold"
                          type="text"
                          name="products_pitched"
                          value={formData.products_sold}
                          onChange={handleChange}
                          variant="outlined"
                          disabled={!disabled}
                        />
                      </Grid>
                    )}
                    {products && (
                      <>
                        <Grid item xs={11} sm={5} md={4} lg={3}>
                          <TextField
                            fullWidth
                            label="Products Pitched"
                            type="text"
                            name="products_pitched"
                            value={formData.products_pitched}
                            onChange={handleChange}
                            variant="outlined"
                            disabled={!disabled}
                          />
                        </Grid>
                        <Grid item xs={11} sm={5} md={4} lg={3}>
                          <TextField
                            fullWidth
                            label="Products Sold"
                            type="number"
                            name="products_sold"
                            value={formData.products_sold}
                            onChange={handleChange}
                            variant="outlined"
                            disabled={!disabled}
                          />
                        </Grid>
                        <Grid item xs={11} sm={5} md={4} lg={3}>
                          <TextField
                            fullWidth
                            label="Price Quoted"
                            type="number"
                            name="price_quoted"
                            value={formData.price_quoted}
                            onChange={handleChange}
                            variant="outlined"
                            disabled={!disabled}
                          />
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        align="left"
                        style={{
                          background:
                            "linear-gradient(90deg, rgba(18,18,162,1) 5%, rgba(31,25,144,1) 37%, rgba(255,255,255,1) 100%)",
                          fontSize: "15px",
                          fontWeight: "bold",
                          color: "white",
                          paddingLeft: "20px",
                        }}
                      >
                        Sale Details
                      </Typography>
                    </Grid>
                    <Grid item xs={11} sm={5} md={4} lg={3}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel>Sales Type</InputLabel>
                        <Select
                          label="Sales Type"
                          name="sales_type"
                          value={formData.sales_type}
                          onChange={handleChange}
                          disabled={!disabled}
                        >
                          <MenuItem value="new">New </MenuItem>
                          <MenuItem value="renewal"> Renewal </MenuItem>
                          <MenuItem value="balance"> Balance </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    
                    <Grid item xs={11} sm={5} md={4} lg={3}>
                      <TextField
                        fullWidth
                        label="Sales Amount"
                        type="number"
                        name="sale_amount"
                        value={formData.sale_amount}
                        onChange={handleChange}
                        variant="outlined"
                        disabled={!disabled}
                      />
                    </Grid>

                    <Grid item xs={11} sm={5} md={4} lg={3}>
                      <TextField
                        select
                        fullWidth
                        label="GST"
                        name="gst"
                        value={formData.gst}
                        variant="outlined"
                        onChange={handleChange}
                      >
                        <MenuItem disabled>
                          <b>GST</b>
                        </MenuItem>
                        <MenuItem value={18}>18%</MenuItem>
                        <MenuItem value={0}>0%</MenuItem>
                      </TextField>
                    </Grid>


                    <Grid item xs={11} sm={5} md={4} lg={3}>
                      <TextField
                        fullWidth
                        label="Total Amount"
                        type="number"
                        name="total_amount"
                        value={formData.total_amount}
                        onChange={handleChange} // No need to change the value of Total Amount
                        variant="outlined"
                        disabled={!disabled}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={11} sm={5} md={4} lg={3}>
                      <TextField
                        fullWidth
                        label="Contract Signed Date"
                        type="date"
                        name="contract_signed_date"
                        value={formData.contract_signed_date}
                        onChange={handleChange}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled={!disabled}
                      />
                    </Grid>

                    <Grid item xs={11} sm={5} md={4} lg={3}>
                      <TextField
                        fullWidth
                        label="Client Name"
                        name="client_name"
                        value={formData.client_name}
                        onChange={handleChange}
                        variant="outlined"
                        disabled={!disabled}
                      />
                    </Grid>

                    <Grid item xs={11} sm={5} md={4} lg={3}>
                      <TextField
                        fullWidth
                        label="Client Phone Number"
                        type="number"
                        name="client_phone_number"
                        value={formData.client_phone_number}
                        onChange={handleChange}
                        variant="outlined"
                        disabled={!disabled}
                      />
                    </Grid>

                    <Grid item xs={11} sm={5} md={4} lg={3}>
                      <TextField
                        fullWidth
                        label="Client Email"
                        type="email"
                        name="client_email"
                        value={formData.client_email}
                        onChange={handleChange}
                        variant="outlined"
                        disabled={!disabled}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        align="left"
                        style={{
                          background:
                            "linear-gradient(90deg, rgba(18,18,162,1) 5%, rgba(31,25,144,1) 37%, rgba(255,255,255,1) 100%)",
                          fontSize: "15px",
                          fontWeight: "bold",
                          color: "white",
                          paddingLeft: "20px",
                        }}
                      >
                        Payment Details
                      </Typography>
                    </Grid>
                    <Grid item xs={11} sm={5} md={4} lg={3}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel>Adv/Balance Amount</InputLabel>
                        <Select
                          label="Adv/Balance Amount"
                          name="adv_bal_amount"
                          onChange={handleSelectChange}
                          style={{ marginTop: "8px" }}
                          id="outlined-size-small"
                          size="small"
                          value={formData.adv_bal_amount}
                          disabled={!disabled}
                        >
                          <MenuItem value="advance">Advance Amount</MenuItem>
                          <MenuItem value="balance">Balance Amount</MenuItem>
                          <MenuItem value="total">Total Amount</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    {selectedOption === "advance" && (
                      <React.Fragment>
                        <Grid item xs={11} sm={5} md={4} lg={3}>
                          <TextField
                            fullWidth
                            label="Clearance Amount"
                            type="number"
                            name="clearance_amount"
                            value={formData.clearance_amount}
                            variant="outlined"
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid item xs={11} sm={5} md={4} lg={3}>
                          <TextField
                            fullWidth
                            label="Balance Amount"
                            type="number"
                            name="balance_amount"
                            value={
                              formData.balance_amount === "0" ||
                              formData.balance_amount === 0
                                ? "0"
                                : formData.balance_amount
                            }
                            variant="outlined"
                          />
                        </Grid>
                      </React.Fragment>
                    )}

                    {selectedOption === "balance" && (
                      <React.Fragment>
                        <Grid item xs={11} sm={5} md={4} lg={3}>
                          <TextField
                            fullWidth
                            label="remaining"
                            type="number"
                            name="remaining"
                            placeholder={formData.clearance_amount}
                            value={formData.remaining}
                            variant="outlined"
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid item xs={11} sm={5} md={4} lg={3}>
                          <TextField
                            fullWidth
                            label="Balance Amount1"
                            type="number"
                            name="balance_amount1"
                            value={formData.balance_amount1}
                            variant="outlined"
                          />
                        </Grid>
                      </React.Fragment>
                    )}

                    {selectedOption === "total" && (
                      <React.Fragment>
                        <Grid item xs={11} sm={5} md={4} lg={3}>
                          <TextField
                            fullWidth
                            label="net_amount"
                            type="number"
                            name="net_amount"
                            value={formData.net_amount}
                            variant="outlined"
                            onChange={handleChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>

                        <Grid item xs={11} sm={5} md={4} lg={3}>
                          <TextField
                            fullWidth
                            label="Balance Amount2"
                            type="number"
                            name="balance_amount2"
                            value={formData.balance_amount2}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                      </React.Fragment>
                    )}

                    <Grid item xs={11} sm={5} md={4} lg={3}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel>Payment Mode</InputLabel>
                        <Select
                          label="Payment Mode"
                          name="payment_mode"
                          value={formData.payment_mode}
                          onChange={handleChange}
                          style={{ marginTop: "8px" }}
                          id="outlined-size-small"
                          size="small"
                        >
                          <MenuItem value="cash">Cash </MenuItem>
                          <MenuItem value="Check"> Check </MenuItem>
                          <MenuItem value="net_banking"> Net Banking </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    align="left"
                    style={{
                      background:
                        "linear-gradient(90deg, rgba(18,18,162,1) 5%, rgba(31,25,144,1) 37%, rgba(255,255,255,1) 100%)",
                      fontSize: "15px",
                      fontWeight: "bold",
                      color: "white",
                      paddingLeft: "20px",
                    }}
                  >
                    Comments
                  </Typography>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={12}>
                  <TextField
                    fullWidth
                    label="Notes"
                    rows={3}
                    name="notes"
                    value={formData.notes}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>

                <br />

                <Grid item xs={12} sm={4}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default Dsr;