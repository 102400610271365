// import React, { useEffect, useState } from "react";
// import {
//   Sidebar,
//   Menu,
//   MenuItem,
//   SubMenu,
//   sidebarClasses,
// } from "react-pro-sidebar";
// import Box from "@mui/material/Box";
// import {
//   FaUsers,
//   FaUserTie,
//   FaIndustry,
//   FaToggleOn,
//   FaToggleOff,
// } from "react-icons/fa";
// import { FaChartSimple } from "react-icons/fa6";
// import { BiSolidSpreadsheet } from "react-icons/bi";
// import { RiUserShared2Fill } from "react-icons/ri";
// import { BsCalendarWeekFill, BsDatabaseFillAdd } from "react-icons/bs";
// import { AiOutlineAreaChart, AiOutlineAudit } from "react-icons/ai";
// import { MdOutlineCrisisAlert } from "react-icons/md";
// import { GoProjectSymlink } from "react-icons/go";
// import { MdAccountCircle } from "react-icons/md";
// import "../ComponentsCSS/Sidebar.css";
// import { Link } from "react-router-dom";
// import emobomo from "../images/emobomo.png";

// const EmployeeSideBar = () => {
//   const [width, setWidth] = useState(window.innerWidth);
//   const [collapse, setCollapse] = useState(false);
//   const [display, setDisplay] = useState("");
//   const [breakPoint, setBreakPoint] = useState(false);
//   const [userData, setUserData] = useState({});

//   useEffect(() => {
//     const handleResize = () => {
//       const windowWidth = window.innerWidth;
//       setWidth(windowWidth);
//       console.log(windowWidth);
//       if (windowWidth < 600) {
//         setCollapse(true);
//         setDisplay("");
//         setBreakPoint(true);
//       } else {
//         setCollapse(false);
//         setDisplay("display");
//         setBreakPoint(false);
//       }
//     };

//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   const toggleCollapse = () => {
//     setCollapse(!collapse);
//     setBreakPoint(!collapse);
//     // Toggle breakPoint state opposite to collapse state
//   };



//    useEffect(() => {
//      const userDataString = localStorage.getItem("userData");
//      if (userDataString) {
//        const userData = JSON.parse(userDataString);
//        setUserData(userData);
//      }
//    }, []);

//   return (
//     <div className="sidebar">
//       <Sidebar
//         collapsed={collapse}
//         rootStyles={{
//           [`.${sidebarClasses.container}`]: {
//             backgroundColor: "#00056b",
//             height: "100vh",
//             paddingTop: "40px",
//             position: "relative",
//             bottom: "65px",
//           },
//         }}
//       >
//         <Menu
//           menuItemStyles={{
//             button: ({ level, disabled }) => ({
//               color: disabled ? "white" : "white",
//               "&:hover": {
//                 backgroundColor: "#8a8dc5",
//               },
//             }),
//           }}
//         >
//           <Box
//             component="img"
//             // sx={{
//             //   height: 50,
//             //   paddingLeft: 30,
//             // }}
//             className="logo"
//             alt="Your logo."
//             src={emobomo}
//           />
//           <h3 className="Admin"> {userData.username} </h3>
//           {/* <h3 className="Admin"> Admin </h3> */}
//           <Link to="/Dashboard">
//             <MenuItem icon={<FaChartSimple />}> DashBoard </MenuItem>
//           </Link>
//           <SubMenu icon={<FaUsers />} className="Sub-Menu" label="HRMS">
//             <MenuItem icon={<FaUserTie />}> Employees </MenuItem>
//             <MenuItem icon={<BiSolidSpreadsheet />}> Attendance </MenuItem>
//             <MenuItem icon={<RiUserShared2Fill />}> Leaves </MenuItem>
//             <Link to="/Holiday_List">
//               <MenuItem icon={<BsCalendarWeekFill />}> Holiday List </MenuItem>
//             </Link>
//           </SubMenu>

//           <MenuItem icon={<AiOutlineAudit />}> Performance </MenuItem>

//           <MenuItem icon={<AiOutlineAreaChart />}> Reports </MenuItem>
//           <SubMenu
//             className="Sub-Menu"
//             icon={<MdOutlineCrisisAlert />}
//             label="Sales"
//           >
//             <Link to="/EmployeeDsr">
//               <MenuItem icon={<BsDatabaseFillAdd />}> DSR </MenuItem>
//             </Link>
//             <Link to="/EmployeeTable">
//               <MenuItem icon={<BsDatabaseFillAdd />}> Manage DSR </MenuItem>
//             </Link>
//             <Link to="/">
//               <MenuItem icon={<GoProjectSymlink />}> Projects </MenuItem>
//             </Link>
//             <MenuItem icon={<FaIndustry />}> Company </MenuItem>
//           </SubMenu>
//           <MenuItem icon={<MdAccountCircle />}> Employee Accounts </MenuItem>
//         </Menu>
//       </Sidebar>
//       <div
//         className={`toggle-button ${display ? "" : "hidden"}`}
//         onClick={toggleCollapse}
//       >
//         {collapse ? (
//           <FaToggleOn className="toggle-icon" />
//         ) : (
//           <FaToggleOff className="toggle-icon" />
//         )}
//       </div>
//     </div>
//   );
// };

// export default EmployeeSideBar;


import React, { useEffect, useState } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  sidebarClasses,
} from "react-pro-sidebar";
import Box from "@mui/material/Box";
import {
  FaAlignJustify,
  FaUsers,
  FaUserTie,
  FaIndustry,
  FaToggleOn,
  FaToggleOff,
} from "react-icons/fa";
import { FaChartSimple } from "react-icons/fa6";
import { BiSolidSpreadsheet } from "react-icons/bi";
import { RiUserShared2Fill } from "react-icons/ri";
import { BsCalendarWeekFill, BsDatabaseFillAdd } from "react-icons/bs";
import { AiOutlineAreaChart, AiOutlineAudit } from "react-icons/ai";
import { MdOutlineCrisisAlert } from "react-icons/md";
import { GoProjectSymlink } from "react-icons/go";
import { MdAccountCircle } from "react-icons/md";
import "../ComponentsCSS/Sidebar.css";
import { Link } from "react-router-dom";
import emobomo from "../images/emobomo.png";

const EmployeeSideBar = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [collapse, setCollapse] = useState(false);
  const [display, setDisplay] = useState("");
  const [breakPoint, setBreakPoint] = useState(false);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      setWidth(windowWidth);
      console.log(windowWidth);
      if (windowWidth < 600) {
        setCollapse(true);
        setDisplay("");
        setBreakPoint(true);
      } else {
        setCollapse(false);
        setDisplay("display");
        setBreakPoint(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleCollapse = () => {
    setCollapse(!collapse);
    setBreakPoint(!collapse);
    // Toggle breakPoint state opposite to collapse state
  };

  useEffect(() => {
    const userDataString = localStorage.getItem("userData");
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      setUserData(userData);
    }
  }, []);

  return (
    <div className="sidebar">
      <Sidebar
        collapsed={collapse}
        rootStyles={{
          [`.${sidebarClasses.container}`]: {
            backgroundColor: "#325c67",
            height: "100vh",
            paddingTop: "40px",
            position: "relative",
            bottom: "65px",
          },
        }}
      >
        <Menu
          menuItemStyles={{
            button: ({ level, disabled }) => ({
              color: disabled ? "white" : "white",
              "&:hover": {
                backgroundColor: "#8a8dc5",
              },
            }),
          }}
        >
          <Box
            component="img"
            // sx={{
            //   height: 50,
            //   paddingLeft: 30,
            // }}
            className="logo"
            alt="Your logo."
            src={emobomo}
          />

          <h3 className="Admin"> {userData.username} </h3>

          <Link to="/EmployeeDashboard">
            <MenuItem icon={<FaChartSimple />}> DashBoard </MenuItem>
          </Link>

          <Link to="/EmployeeDsr">
              <MenuItem icon={<BsDatabaseFillAdd />}> DSR </MenuItem>
            </Link>

          {/* <SubMenu icon={<FaUsers />} className="Sub-Menu" label="HRMS">
            <MenuItem icon={<FaUserTie />}> Employees </MenuItem>
            <MenuItem icon={<BiSolidSpreadsheet />}> Attendance </MenuItem>
            <MenuItem icon={<RiUserShared2Fill />}> Leaves </MenuItem>
            <Link to="/Holiday_List">
              <MenuItem icon={<BsCalendarWeekFill />}> Holiday List </MenuItem>
            </Link>
          </SubMenu> */}

          {/* <MenuItem icon={<AiOutlineAudit />}> Performance </MenuItem> */}

          <SubMenu
            className="Sub-Menu"
            icon={<AiOutlineAreaChart />}
            label="Reports"
          >
            <Link to="/EmployeeTable">
              <MenuItem icon={<BsDatabaseFillAdd />}> Manage DSR </MenuItem>
            </Link>

          </SubMenu>


          {/* <SubMenu
            className="Sub-Menu"
            icon={<MdOutlineCrisisAlert />}
            label="Sales"
          >
            <Link to="/">
              <MenuItem icon={<GoProjectSymlink />}> Projects </MenuItem>
            </Link>
            <MenuItem icon={<FaIndustry />}> Company </MenuItem>
          </SubMenu> */}


          <MenuItem icon={<MdAccountCircle />}> Employee Accounts </MenuItem>


        </Menu>
      </Sidebar>
      <div
        className={`toggle-button ${display ? "" : "hidden"}`}
        onClick={toggleCollapse}
      >
        {collapse ? (
          <FaToggleOn className="toggle-icon" />
        ) : (
          <FaToggleOff className="toggle-icon" />
        )}
      </div>
    </div>
  );
};

export default EmployeeSideBar;
