import React, { useEffect, useState } from "react";
import {
  BsFillArchiveFill,
  BsFillGrid3X3GapFill,
  BsPeopleFill,
  BsFillBellFill,
} from "react-icons/bs";
import axios from "axios";
import Header from "../Components/Header";
import SideBar from "../Components/SideBar";

function DashBoard() {

const title = "Dashboard";
const [userData, setUserData] = useState({});
const [role, setRole] = useState("");
const [totalRows, setTotalRows] = useState(null);
const [followUps, setFollowUps] = useState(null);
const [meetings, setMeetings] = useState(null);
const [sales, setSales] = useState(null);

useEffect(() => {
  const userDataString = localStorage.getItem("userData");
  if (userDataString) {
    const userData = JSON.parse(userDataString);
    setUserData(userData);
    setRole(userData.role);
  }
}, []);

const fetchData = async () => {
  try {
    const response = await axios.post("http://localhost:5002/dashboard", {
      role: role,
      sales_team: userData.username,
    });
    setTotalRows(response.data.totalRows);
    setFollowUps(response.data.followUps);
    setMeetings(response.data.meetings);
    setSales(response.data.sales);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

useEffect(() => {
  if (role) {
    fetchData();
  }
}, [role]);


  return (
    <main className="main-container">
      <Header title={title} />

      <div style={{ display: "flex", flexDirection: "row" }}>
        <SideBar />
        {/* <div className="main-title">
          <h3>DASHBOARD</h3>
        </div> */}
        {/* <div> */}
        <div className="main-cards">
          <div className="row-cards">
            <div className="card">
              <div className="card-inner">
                <h3>Monthly Calls </h3>
                <BsFillArchiveFill className="card_icon" />
              </div>
              {totalRows !== null && <h1>{totalRows}</h1>}
            </div>
            <div className="card">
              <div className="card-inner">
                <h3>Monthly Meetings</h3>
                <BsFillGrid3X3GapFill className="card_icon" />
              </div>
              {meetings !== null && <h1>{meetings}</h1>}
            </div>
          </div>
          <div className="row-cards">
            <div className="card">
              <div className="card-inner">
                <h3>Monthly Prospects</h3>
                <BsPeopleFill className="card_icon" />
              </div>
              {followUps !== null && <h1>{followUps}</h1>}
            </div>
            <div className="card">
              <div className="card-inner">
                <h3>Monthly Sales</h3>
                <BsFillBellFill className="card_icon" />
              </div>
              {sales !== null && <h1>{sales}</h1>}
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </main>
  );
}

export default DashBoard;