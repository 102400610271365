import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import "../ComponentsCSS/Holidays.css";
import axios from "axios";

const AddHolidays = ({ closePopUp }) => {
  const [formData, setFormData] = useState({
    holiday_name: "",
    holiday_type: "",
    date: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);

    axios
      .post("http://localhost:5002/holidays", formData)
      .then((response) => {
        console.log(response.data);
        window.alert("successfully submitted");
      })
      .catch((error) => {
        console.log(error);
        window.alert("Error submitting data");
      });
  };

  return (
    <div class="modal-overlay">
      <div class="modal">
        <div class="modal-header">
          <h2>Add Holidays</h2>
          <button onClick={() => closePopUp(false)} class="close-modal-btn">
            &times;
          </button>
        </div>
        <div class="modal-content">
          <form id="form" onSubmit={handleSubmit}>
            <br></br>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  fullWidth
                  label="Holiday Name"
                  name="holiday_name"
                  value={formData.holiday_name}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  fullWidth
                  label="Holiday Type"
                  name="holiday_type"
                  value={formData.holiday_type}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  fullWidth
                  label="Date"
                  type="date"
                  name="date"
                  // value={formData.date}
                  onChange={handleChange}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddHolidays;
