import React from 'react'

const HRDashboard = () => {

  return (
    <>
      <h1>HR screen</h1>
    </>
  )
}

export default HRDashboard
