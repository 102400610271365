import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Components/Login";
import HRDashboard from "./Pages/HRDashboard"
import SHDashboard from "./Pages/SHDashboard"
import { createTheme, ThemeProvider } from "@mui/material/styles";
import DsrTable from "./Pages/DsrTable";
import DsrUpdate from "./Pages/DsrUpadate";
import DashBoard from "./Pages/Dashboard";
import HolidayList from "./Pages/HolidayList";
import Dsr from "./Pages/Dsr";
import EmployeeTable from "./Pages/EmployeeTable"
import EmployeeDsr from "./Pages/EmployeeDsr";
import EmployeeUpdate from "./Pages/EmployeeUpdate";
import EmployeeDashboard from "./Pages/EmployeeDashboard";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 860,
      lg: 1050, // Change the width at the lg breakpoint
      xl: 1400,
    },
  },
});


function App() {


  return (
    <>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/dsr" element={<Dsr/>} />
            <Route path="/DsrUpdate/:dsr_id" element={<DsrUpdate />} />
            <Route path="/dsrTable" element={<DsrTable />} />
            <Route path="/HRDashboard" element={<HRDashboard />} />
            <Route path="/SHDashboard" element={<SHDashboard />} />

            <Route path="/Dashboard" element={<DashBoard />} />
            <Route path="/Holiday_List" element={<HolidayList />} />
            
            <Route path="/EmployeeDsr" element={<EmployeeDsr />} />
            <Route path="/EmployeeTable" element={<EmployeeTable/>} />
            <Route path="/EmployeeUpdate" element={<EmployeeUpdate/>} />
            <Route path="/EmployeeDashboard" element={<EmployeeDashboard />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
