import React from 'react'

const SHDashboard = () => {

  return (
    <>
      <h1>SalesHead</h1>
    </>
  )
}

export default SHDashboard
