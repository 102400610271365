import React, { useState, useEffect, useRef } from "react";
import { format } from "date-fns";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Stack,
} from "@mui/material";
import axios from "axios";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import Header from "../Components/Header";
import SideBar from "../Components/SideBar";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import AddHolidays from "../Components/AddHolidays";

const HolidayList = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [holidayData, setHolidaysData] = useState([]);

  
  useEffect(() => {
    axios.get("http://localhost:5002/getHolidays").then((res) => {
      setHolidaysData(res.data);
  
    });
  }, []);


  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#eb922c",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#d5a2673d",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <div className="background">
      <Header />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <SideBar />

        <Container
          className="table-container"
          sx={{ margin: 0, padding: 0, maxWidth: "100%" }}
        >
          <div id="main">
            <Typography variant="h4" align="center" gutterBottom>
              Holiday List
            </Typography>
            {/* <div className="Search">
            <Stack spacing={4}>
              <Stack spacing={5} direction={"row"}>
                <TextField
                  style={{ width: "224px" }}
                  label="Search by DSR ID"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                />

                <Button
                  variant="contained"
                  style={{
                    color: "white",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                  component={Link}
                  to="/dsr"
                >
                  {" "}
                  Add{" "}
                </Button>
              </Stack>
            </Stack>
          </div> */}
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                sx={{ margin: "10px 10px 10px 0px" }}
                variant="contained"
                onClick={() => {
                  setIsPopupOpen(true);
                }}
              >
                Add
              </Button>
              {/* Button to open the popup */}

              {/* Render the popup conditionally */}
              {isPopupOpen && <AddHolidays closePopUp={setIsPopupOpen} />}
            </div>
            <TableContainer component={Paper}>
              <Table>
                <TableHead
                  sx={{
                    ".MuiTableCell-root": { fontWeight: "600" },
                  }}
                >
                  <TableRow>
                    <StyledTableCell>S.NO</StyledTableCell>
                    <StyledTableCell>Holiday_Name</StyledTableCell>
                    <StyledTableCell>Holiday_Date</StyledTableCell>
                    <StyledTableCell>Holiday_Type</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {holidayData.map((row) => (
                    <StyledTableRow key={row.holidays_id}>
                      <StyledTableCell>{row.id}</StyledTableCell>
                      <StyledTableCell>{row.holiday_name}</StyledTableCell>
                      <StyledTableCell>{row.holiday_type}</StyledTableCell>
                      <StyledTableCell>
                        {format(new Date(row.date), "dd-MM-yyyy")}
                      </StyledTableCell>
                      <StyledTableCell>Active</StyledTableCell>
                      <StyledTableCell>
                        <button>
                          <EditCalendarIcon />
                        </button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {/* <Button variant="contained" color="primary" onClick={handleScrollToTop}>
          Scroll To Top
        </Button> */}
        </Container>
      </div>
    </div>
  );
};

export default HolidayList;
